@import "./../../node_modules/antd/lib/date-picker/style/index";
@import "./../../components/Layout/common";
@import "./header_static";
//@import "./midautumn";
@import "./usercenter";
@import "./maintain";
@import "./autoSuggestLst";
@import "./modal";
@import "./home";
@import "./im";

@import "./about.less";
@import "./depositlesson.less";
@import "./message.less";
@import "./cardId.less";
@import "./wallet.less";
@import "./modal_wallet_deposit.less";
@import "./withdrawlesson.less";

//@import "./redrain.less";
@import "./impo.less";
@import "./promotions.less";
@import "./promotions-mine-promo.less";
@import "./Promotions/AppliedHistoryCard.less";
@import "./sponsor.less";
// @import "./survey.less";
@import "./usdt.less";
@import "./headTitleUsdt.less";
// @import "./survey_new.less";

@import "./ambassador.less";
@import "./whoisambassador.less";
@import "./download.less";
@import "./drawer.less";
@import "./footer.less";

@import "./diamondClub.less";

@import "./registerbonus.less";
@import "./game_iframe.less";
@import "./game_lobby.less";
@import "./game_public.less";
@import "./selfexclusion.less";
@import "./DetailGift/dailygift.less";
@import "./DetailGift/detailmodal.less";
@import "./DetailGift/dailygiftAddress.less";
@import "./DetailGift/dailygiftAddressDetail.less";
@import "./DetailGift//selectArddress.less";
@import "./formItem.less";
@import "./resetpasswordcv.less";
@import "./help.less";

@import "./../less/react-photo-view.less";

@import "./vip.less";
@import "~antd/dist/antd.less";
//@import "./worldcup.less";
@import "./common.less";

// UI
@import "./UI/Select.less";
@import "./UI/Datepicker25.less";
@import "./UI/Tab2025.less";
@import "./UI/Toast2025.less";
@import "./UI/Mdl2025.less";
@import "./UI/Ddown.less";
@import "./UI/Pagination.less";
@import "./UI/TopOfWithdrawalInfo.less";
@import "./other.less";

//上传文件
@import "./upload.less";

//2024欧洲杯
@import "./euro2024.less";

// 錢包教學
@import "./tutorial.less";

// 客服
@import './customerService.less';

.footer-platform-wrap .icon-item2._bili { /* unknow: no updates when put footer.less, so put at merge.less */
  width:97px;
  background-image: url(/cn/img/home/footer-sprite-bili.png);
  &:hover {
      background-image: url(/cn/img/home/footer-sprite-bili-hover.png)
  }
}

@hack: true;@import '/buildDir/data/less/antd-custom.less';